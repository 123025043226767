<template>
  <div class="flex main-div  main-height-width-login">
    <div class="brand-div-login">
      <img
        src="@/assets/capacio/login/login-girl.png"
        alt=""
        class="bottom-img"
      >
      <img
        src="@/assets/capacio/login/logo.svg"
        alt=""
        class="logo-img"
      >
    </div>
    <div class="w-1/2 login-form">
      <div class="flex login-form-in">
        <div class="w-3/5 login-div">
          <h3 class="text-3xl font-semibold py-8">
            Welcome to Capacio
          </h3>
          <h4 class="text-base font-normal mb-8">
            Log in to Capacio via email.
          </h4>
          <login-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue'

export default {
  components: {
    LoginForm
  }
}
</script>

<style lang="scss">
@import "../login.scss";
</style>
