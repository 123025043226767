<template>
  <div class="">
    <div class="form-control">
      <div class="form-control">
        <label class="label">
          <span class="text-lg label-text text-primary-dark" />
        </label>
        <input
          class="py-6 px-4 mb-4 leading-tight border text-base focus:outline-none"
          id="grid-first-name"
          type="text"
          name="username"
          v-model="email"
          :class="{ 'input-error': errorMsg || verifyEmailMsg }"
          placeholder="Email address"
        >
      </div>
      <div class="form-control">
        <label class="label">
          <span class="text-lg label-text text-primary-dark" />
        </label>
        <input
          class="text-gray-700 border py-6 px-4 leading-tight text-base focus:outline-none"
          id="grid-last-name"
          type="password"
          name="password"
          v-model="password"
          placeholder="Password"
          :class="{ 'input-error': errorMsg || verifyEmailMsg }"
        >
        <label
          v-if="errorMsg"
          class="label"
        >
          <span class="label-text-alt">Wrong email or password</span>
        </label>
        <label
          v-if="verifyEmailMsg"
          class="label"
        >
          <span class="label-text-alt">Please verify your email</span>
        </label>
      </div>
      <div class="text-right py-6">
        <router-link
          class="link underline text-xs font-normal"
          to="/login/forgot"
        >
          Forgot password?
        </router-link>
      </div>

      <button
        name="submit_button"
        class="btn-primary login-btn
        "
        @click="login"
      >
        Log In
      </button>
      <div class="py-6 text-left">
        <router-link
          class="link text-sm font-normal no-underline"
          to="/signup"
        >
          Don’t have an account?
          <span class="underline font-semibold">Create one now </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import jwtDecode from 'jwt-decode'
export default {
  data () {
    return {
      email: '',
      password: '',
      verifyEmailMsg: false,
      errorMsg: false
    }
  },
  methods: {
    async login () {
      const user = { email: this.email.toLowerCase(), password: this.password }
      try {
        this.$store.commit('USER/LOGOUT_USER')
        this.$store.commit('ADMIN/LOGOUT_ADMIN')
        await this.$store.dispatch('USER/loginPassword', user)

        this.$crisp.setTokenId(jwtDecode(this.$store.state.USER.user.token).id)
        this.$crisp.load()

        this.$crisp.user.setEmail(this.$store.state.USER.user.email, this.$store.state.USER.user.crispEmailSignature)
        this.$crisp.user.setNickname(this.$store.state.USER.user.firstName)
        this.$crisp.chat.show()

        if (this.$store.state.USER.user.subscriptionCancelled) {
          this.$router.push({ name: 'Checkout', query: { fromTrial: true, inactiveSubscription: true } })
        } else {
          this.$router.push(this.$route.query.redirect || '/home')
        }
      } catch (error) {
        if (error.response.data === 'Account email not verified') {
          this.verifyEmailMsg = true
        } else {
          this.errorMsg = true
        }
      }
    }
  }
}
</script>

<style></style>
